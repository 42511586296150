import React from "react";
import Laptop from "./images/laptop.png";

const Section = () => {
  return (
  <div>
    <div className="text-4xl font-bold text-center">Pricing for professionals</div>
    <div className="mx-4 my-4 flex gap-6 md:gap-4 flex-col md:flex-row justify-between">
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$4,000</div>
            <div className="text-sm md:text-lg">for 1 month</div>
          </div>
        </div>
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$10,000</div>
            <div className="text-sm md:text-lg">for 3 months</div>
          </div>
        </div>
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$16,000</div>
            <div className="text-sm md:text-lg">for 6 months</div>
          </div>
        </div>
    </div>
    <div className="text-4xl font-bold text-center">Pricing for students</div>
    <div className="text-sm lg:text-sm text-gray-g3 text-center">
            Students must sign up with .edu email account and provide correct LinkedIn information to receive this discount
    </div>
    <div className="mx-4 my-4 flex gap-6 md:gap-4 flex-col md:flex-row justify-between">
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$1,000</div>
            <div className="text-sm md:text-lg">for 1 month</div>
          </div>
        </div>
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$2,500</div>
            <div className="text-sm md:text-lg">for 3 months</div>
          </div>
        </div>
        <div className="w-11/12 md:w-1/3 flex flex-col items-center text-center">
          <div className="my-6">
            <div className="text-green-g1 text-5xl font-bold">$4,000</div>
            <div className="text-sm md:text-lg">for 6 months</div>
          </div>
        </div>
    </div>
    <div className="text-4xl font-bold text-center">Student Specials</div>
    <div className="text-sm lg:text-sm text-gray-g3 text-center">
      For students seeking extended support throughout academic enrollment.
    </div>
    <div className="mx-4 my-4 flex gap-6 md:gap-4 flex-col md:flex-row justify-between">
      <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
        <div className="my-6">
          <div className="text-green-g1 text-5xl font-bold">$7,000</div>
          <div className="text-sm md:text-lg">for 1 year</div>
        </div>
      </div>
      <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
        <div className="my-6">
          <div className="text-green-g1 text-5xl font-bold">$11,000</div>
          <div className="text-sm md:text-lg">for duration of academic study</div>
        </div>
      </div>
    </div>
    <div className="text-4xl font-bold text-center">Last Minute Specials for Professionals</div>
    <div className="text-sm lg:text-sm text-gray-g3 text-center">
      Every now and then, candidates find themselves thrust into interviews with 
      just a few days' notice. To accommodate these professionals, we're offering two 
      last-minute subscription specials:
    </div>
    <div className="mx-4 my-4 flex gap-6 md:gap-4 flex-col md:flex-row justify-between">
      <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
        <div className="my-6">
          <div className="text-green-g1 text-5xl font-bold">$999</div>
          <div className="text-sm md:text-lg">for 3 days</div>
        </div>
      </div>
      <div className="w-11/12 md:w-1/2 flex flex-col items-center text-center">
        <div className="my-6">
          <div className="text-green-g1 text-5xl font-bold">$1,599</div>
          <div className="text-sm md:text-lg">for 1 week</div>
        </div>
      </div>
    </div>
    <div className="md:mt-2">
        <div className="text-sm lg:text-lg text-gray-g3 text-center">
          <strong>For bulk discounts, please contact 
            <a href="mailto:support@quantfinanceprep.com" className="text-blue-500"> support@quantFinancePrep.com</a>
          </strong>
        </div>
        <div className="text-sm lg:text-lg text-gray-g3 text-center">
          <a href="https://www.levels.fyi/internships/" className="text-blue-500" target="_blank">
            {"Recoup your investment in as little as 1 month!"}
          </a>
        </div>
        <br/>
    </div>
    </div>

  );
};

export default Section;
